.page-about{
	.sec-main-visual .inner{
		display: flex;
		justify-content: center;
	}
	.sec-main-visual .inner .main-visual__ttl{
		padding-left: 15px;
	}
}
.sec-main-visual {
	position: relative;
	.main-visual__img {
		position: relative;
		img {
			width: 100%;
		}
	}
	.inner {
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		.main-visual__ttl {
			position: absolute;
			top: 0;
			padding-right: 15px;
			text-align: center;
			width: auto;
			@include mq() {
				position: static;
				margin-bottom: 20px;
				margin-top: 20px;
				padding-left: 0!important;
				padding-right: 0!important;
			}
		}
		@include mq() {
			position: static;
			transform: none;
		}
	}
	.inner-1800 {
		display: block;
		width: 100%;
		max-width: 1826px;
		padding: 0 15px;
		margin: 0 auto;
		position: absolute;
		bottom: 32px;
		@include mq() {
			position: static;
		}
	}

	.main-visual__list {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-end;
		@include mq() {
			display: block;
			margin-top: 80px;
			text-align: center;
			margin-bottom: 30px;
		}
		.item {
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 50px;
				color: #231815;
				font-weight: 400;
				@include font-size(40);
				line-height: 1;
				position: relative;
				padding: 17px 18px;
				background: rgba(#fff, 0.8);
				img {
					margin-right: 10px;
					@include mq() {
						width: 15px;
						margin-right: 8px;
					}
				}
				@media screen and(max-width: 1400px) {
					@include font-size(30)
				}
				@include mq {
					padding: 0;
					margin: 0 0px 50px;
					@include font-size(20);
					font-weight: 300;
					color: #231f20;
				}

				//&:after {
				//	position: absolute;
				//	content: '';
				//	bottom: 8px;
				//	left: 50%;
				//	transform: translateX(-50%);
				//	height: 3px;
				//	background: #ffffff;
				//	transition: all .3s ease;
				//	width: 0;
				//	@include mq(mac) {
				//		bottom: 0.452vw;
				//	}
				//	@include mq {
				//		bottom: 0;
				//		background-color: #231f20;
				//		height: 2px;
				//	}
				//}
				//&:hover {
				//	opacity: 1;
				//	&:after {
				//		width: 100%;
				//	}
				//}
			}
		}
	}
}

.sec-main-group {
	padding: 7.883% 0;
	@include mq {
		padding: 5px 25px 80px;
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
	}
}

.main-group {
	&__block {
		width: 71%;
		padding-right: 7.4%;
		@include mq {
			width: 100%;
			padding-right: 0;
			margin-bottom: 80px;
			text-align: center;
		}
	}
	&__embed {
		width: 29%;
		@include mq {
			width: 100%;
			text-align: center;
			img {
				width: 295px;
			}
		}
		iframe {
			@include mq(){
				width: 78.1vw;
				max-width: 435px;
				display: block;
				margin: auto;
			}
			@include mq(xs){
				width: 75.1vw;
			}
			@include mq(md-min){
				height: 42vw;
				max-height: 648px;
				width: 100%;
			}
		}
	}
}