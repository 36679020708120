
.inner {
	display: block;
	width: 100%;
	max-width: 1530px;
	padding: 0 15px;
	margin: 0 auto;
	&-1500{
		max-width: 1530px;
	}
	&-1350 {
		max-width: 1380px;
	}
	&-1200{
		max-width: 1230px;
	}
}


.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	color: inherit;
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
}


.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}


//ttl
.ttl-comp{
	&01{
	
	}
	&02{
		
	}
	&03{
		text-align: center;
		margin-bottom: 106px;
		@include mq {
			margin-bottom: 52px;

		}
	}
}

//text
.txt{
	&-basic{
		line-height: 1.8;
		@include font-size(16);
		@include mq(){
			@include font-size(14);
			line-height: 1.6;
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
}



//btn
.btn {
	&-comp01 {
		@include font-size(40);
		line-height: 1;
		position: relative;
		@include mq(lp) {
			@include font-size(30);
		}
		@include mq {
			@include font-size(20);
		}
		&:before {
			content: '';
			display: inline-block;
			width: 34px;
			height: 44px;
			background: url("../img/index/ico-arrow-right.png") no-repeat;
			background-size: cover;
			background-position: center right;
			vertical-align: middle;
			margin: -1px -4px 0 0;
			@include mq(lp) {
				width: 24px;
				height: 30px;
			}
			@include mq {
				width: 16px;
				height: 19px;
				position: absolute;
				top: 6px;
				left: 0;
			}
		}
		@include mq(){
			padding-left: 18px;
		}
		&:after{
			position: absolute;
			content: '';
			bottom: -5px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			height: 3px;
			background: #231F20;
			-webkit-backface-visibility: hidden;
			-webkit-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
			width: 0;
		}
		&:hover{
			&:after{
				width: 100%;
			}
		}
		span {
			@include mq {
				display: inline-block;
				position: relative;
				padding-bottom: 5px;
				border-bottom: 1px solid #58595B;
			}
		}
		&.mac {
			&:before {
				@include mq {
					margin: -1px 0 0 -1px;
				}
			}
		}
	}
}

// background
.bg {
	&-gray {
		background: #D1D3D4;
	}
}

.txt-notice{
	text-align: center;
	@include font-size(20);
	@include mq(){
		@include font-size(16);
	}

}

area{
	outline: none;
}

.txt-intro{
	text-align: center;
	color: #231F20;
	font-family: "M PLUS 1p", sans-serif;
	@include font-size(16);
	margin-bottom: 30px;
	@include mq(){
		@include font-size(8.5);
		margin-bottom: 20px;
		text-align: left;

	}
}