.sec-contact{
	padding-top: 170px;
	@include mq{
		padding-top: 50px;
	}
	@include mq(sm){
		margin-bottom: 39px;
		padding: 26px 25px 0;
	}
	@include mq(xs){
		padding: 26px 15px 0;
	}
	.ttl-contact{
		margin-bottom: 100px;
		@include mq{
			margin-bottom: 50px;
		}
		@include mq(sm){
			margin-bottom: 41px;
		}
	}
}
.form-contact{
	label{
		@include font-size(40);
		line-height: 1;
		color: #231f20;
		margin-bottom: 15px;
		display: flex;
		align-items: flex-start;
		font-weight: 300;
		@include mq{
			@include font-size(25);
		}
		@include mq(sm){
			@include font-size(18);
			line-height:1.3;
			
			margin-bottom: 2px;
		}
		.requite{
			@include font-size(30);
			color: #ffffff;
			font-weight:300;
			line-height: 1;
			padding: 5px 12px;
			background: #ccb138;
			margin-left: 17px;
			@include mq{
				@include font-size(20);
			}
			@include mq(sm){
				@include font-size(14);
				line-height: 1;
				padding: 3px 7px;
				margin-left: 13px;
			}
		}
		
	}

	input[type="text"],input[type="email"]{
		width: 60%;
		padding: 25px 20px;
		border: 1px solid #231f20;
		@include font-size(25);
		line-height: 1.5;
		@include mq{
			@include font-size(22);
		}
		@include mq(sm){
			@include font-size(18);
		}
		@include mq{
			padding: 18px 15px;
		}
		@include mq(sm){
			width: 100%;
			padding: 3px 10px;
		}
	}
	.inline-group{
		margin-bottom: 50px;
		display: inline-block;
		width: 100%;
		&:last-child{
			margin-bottom: 0;
		}
		@include mq(sm){
			margin-bottom: 38px;
		}
		
	}
	textarea{
		width: 100%;
		resize: none;
		min-height: 700px;
		padding: 15px;
		@include font-size(25);
		line-height: 1.5;
		border: 1px solid #231f20;
		@include mq{
			min-height: 350px;
		}
		@include mq(sm){
			@include font-size(18);
			min-height: 219px;
		}
	}
	&__main{
		margin-bottom: 94px;
		@include mq{
			margin-bottom: 50px;
		}
		@include mq(sm){
			margin-bottom: 17px;
		}
	}
}
.btn-submit{
	margin: 0px 0 167px;
	@include mq{
		margin-bottom: 50px;
	}
	@include mq(sm){
		margin-bottom: 0;
	}
	.btn{
		@include font-size(50);
		line-height: 1;
		font-weight: 300;
		color: #fff;

		text-align: center;
		padding: 15px 61px;
		border: none;
		border-radius: 7px;
		outline: none;
		font-family: $font-mplus;
		box-shadow: none;
		background-image:none;
		background: #ccb138;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-webkit-appearance: none;

		&:hover{
			opacity: 0.7;
			@include mq(){
				opacity: 1;
			}
		}
		@include mq{
			@include font-size(35);
		}
		@include mq(sm){
			@include font-size(20);
			line-height: 1;
			padding: 5px 14px;
		}
	}
}

.mw_wp_form_confirm{
	.form-contact .inline-group{
		@include font-size(25);
		@include mq(){
			@include font-size(16);
		}
	}
	.requite{
		display: none;
	}
	.btn-submit .btn{
		min-width: 350px;
		margin: 0px 10px;
		@include mq(){
			min-width: 230px;
			margin: 7px auto;
		}
	}
	.btn-submit{
			text-align: center;

	}
}

.thanks-content{
	@include font-size(25);
	line-height: 1.6;
	margin-bottom: 100px;
	@include mq(){
		@include font-size(14);
		margin-bottom: 30px;
	}
}

.wpcf7-not-valid-tip{
	@include font-size(20);
	margin-top: 10px;
	@include mq(){
		@include font-size(12);
	}
}

.wpcf7 form .wpcf7-response-output{
	@include font-size(30);
	@include mq(){
		@include font-size(18);
	}
}


form.wpcf7-form.custom-wpcf7c-confirmed{
	.requite{
		display: none;
	}
	input[type="text"],input[type="email"],textarea{
		border: none!important;
		background: none!important;
	}
	textarea{
		min-height: inherit;
	}
	.btn-submit{
		display: flex;
		&>*{
			margin: 0px 5px;
		}
	}
}