
body {
	color: #231F20;
	font-family: $font-mplus;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow: hidden;
}



//header
.header{
	@include font-size(30);
	font-weight: 300;
	line-height: 1.2;
	color: #58595B;
	height: 160px;
	letter-spacing: 2px;
	white-space: nowrap;
	background: white;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	@include mq(mac) {
		@include font-size(25);
		letter-spacing: 1px;
	}
	@include mq(xl) {
		height: 120px;
		@include font-size(20);
		letter-spacing: 0;
	}
	@include mq(ipd) {
		height: 100px;
	}
	@include mq {
		height: 63px;
	}
	&__inner {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		max-width: 1772px;
		padding: 10px 15px 10px 16px;
		margin: 0 auto;
		@include mq {
			padding: 10px 15px;
		}
	}
	&__logo {
		@include mq {
			width: 175px;
		}
		img {
			display: block;
			width: 100%;
		}
	}

	&-nav {
		margin-left: auto;
		@include mq {
			position: fixed;
			top: 20px;
			bottom: 0;
			left: 15px;
			right: 15px;
			background: white;
			opacity: 0;
			pointer-events: none;
			@include smooth-transition;
			background: rgba(#dcdddd,0.9);
			&.on {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	&-menu {
		display: flex;
		align-items: center;
		margin-bottom: 36px;
		@include mq(ipd) {
			margin-bottom: 15px;
		}
		@include mq {
			display: block;
			margin-bottom: 80px;
			text-align: center;
			padding-top: 23px;
		}
		&__item {
			margin-left: 47px;
			position: relative;
			@include mq(mac) {
				margin-left: 30px;
			}
			@include mq(xl) {
				margin-left: 20px;
			}
			@include mq {
				margin-left: 0;
				padding: 22px 15px;
			}
			@include mq(){
				&.has-sub{
					&>a{
						text-decoration: none;
						color: #58595b9c;
					}
				}
				a{
					text-decoration: underline;
					text-underline-position: under;
					font-weight: 500;
				}
			}
			> a {
				display: block;
				position: relative;
				padding: 16px 0;
				@include mq {
					padding: 0;
					display: inline-block;
					padding-bottom: 5px;

				}
				&:before {
					position: absolute;
					content: '';
					bottom: 8px;
					@include h-align;
					height: 3px;
					background: #231F20;
					@include smooth-transition;
					width: 0;
					@include mq {
						display: none;
					}
				}
				.ico {
					display: inline-block;
					vertical-align: middle;
					width: 30px;
					height: 30px;
					position: relative;
					background: #333;
					border-radius: 50%;
					&:before, &:after {
						position: absolute;
						content: '';
						@include v-h-align;
						background: white;
					}
					&:before {
						width: 14px;
						height: 2px;
					}
					&:after {
						height: 14px;
						width: 2px;
					}
				}
				&.show {
					.ico {
						&:after {
							display: none;
						}
					}
				}
			}
			&.active {
				> a {
					&:before {
						width: 100%;
						@include mq {
							display: block;
						}
					}
				}
			}
			&:hover {
				> a {
					opacity: 1;
					&:before {
						width: 100%;
					}
				}
				.sub-menu {
					opacity: 1;
					pointer-events: auto;
					transform: translateY(0);
				}
			}
			.sub-menu {
				background: #D1D3D4;
				padding: 23px 28px;
				@include mq(md-min) {
					position: absolute;
					top: 100%;
					@include smooth-transition;
					opacity: 0;
					pointer-events: none;
					min-width: 280px;
					transform: translateY(10px);
				}
				@include mq {
					//display: none;
					background: none;
				}
				.item {
					margin-bottom: 23px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&-control {
		display: none;
		margin-left: auto;
		width: 25px;
		height: 18px;
		position: relative;
		margin-bottom: 4px;
		@include mq {
			display: block;
		}
		span {
			position: absolute;
			height: 2px;
			background: #58595B;
			width: 100%;
			left: 0;
			@include smooth-transition;
			&:first-child {
				top: 0;
			}
			&:nth-of-type(2) {
				top: 46%;
			}
			&:last-child {
				bottom: 0;
			}
		}
		&.close {
			span {
				&:first-child {
					top: 46%;
					transform: rotate(45deg);
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:last-child {
					bottom: 46%;
					transform: rotate(-45deg);
				}
			}
		}
	}
}

// main-page
.main-page {
	margin-top: 160px;
	@include mq(xl) {
		margin-top: 120px;
	}
	@include mq(ipd) {
		margin-top: 100px;
	}
	@include mq {
		margin-top: 63px;
	}
}


//footer
.footer{
	padding: 51px 15px 40px;
	border-top: 20px solid #939598;
	color: #58595B;
	@include font-size(20);
	line-height: 1;
	text-align: center;
	letter-spacing: 0.75px;
	@include mq(xl) {
		@include font-size(16);
	}
	@include mq {
		@include font-size(12);
		border-width: 10px;
		line-height: 1.25;
		padding: 10px 15px 23px;
		letter-spacing: 0.55px;
	}
	@include mq(xs) {
		letter-spacing: 0px;
		@include font-size(11);
	}
	&-list {
		margin-bottom: 50px;
		display: flex;
		justify-content: center;
		@include font-size(30);
		font-weight: 300;
		letter-spacing: 2px;
		line-height: 1;
		@include mq(xl) {
			@include font-size(23);
		}
		@include mq {
			@include font-size(15);
			letter-spacing: 0;
			margin-bottom: 8px;
		}
		@include mq(sm){
			flex-wrap: wrap;
		}
		&__item {
			padding: 0 43px;
			border-left: 1px solid #939598;
			@include mq {
				padding: 0 25px;
			}
			@include mq(sm){
				width: 50%;
				margin-bottom: 15px;
				&:nth-child(3n){
					border-left: none;
				}
			}
			@include mq(xs) {
				padding: 0 5px;
			}
			&:first-child {
				border-left: none;
			}
		}
	}
}

