.sec-mv{
	position: relative;
	margin-bottom: 52px;
	@include mq {
		padding: 9px 0 0;
		margin-bottom: 81px;
	}
}
.mv {
	&-img {
		@include mq {
			margin-bottom: 79px;
		}
		img {
			display: block;
			width: 100%;
			min-height: 700px;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			@include mq {
				min-height: auto;
			}
		}
	}
	&-content {
		width: 100%;
		max-width: 1770px;
		padding: 0 15px;
		text-align: right;
		@include mq(md-min) {
			position: absolute;
			top: 3.4%;
			@include h-align;
		}
		@include mq {
			text-align: center;
		}
	}
	&-logo {
		padding: 0 0px 9px 0;
		margin-right: -1px;
		@include mq {
			padding: 0 0px 6px 0;
			margin-right: 0;
		}
	}
	&-ttl {
		padding: 0 2px 58px 0;
		@include mq {
			padding: 0 0 20px 0;
		}
	}
}

.sec-guide {
	margin-bottom: 130px;
	margin-top: 50px;
	@include mq(lp) {
		margin-bottom: 80px;
	}
	@include mq {
		margin-bottom: 0px;
		margin-top: 0;
		.inner{
			padding: 0!important;
		}
	}
}
.guide {
	&__head {
		padding: 0 210px;
		margin-bottom: 50px;
		@include mq(mac) {
			padding: 0 8vw;
		}
		@include mq {
			padding: 0 10.6vw;
			margin-bottom: 72px;
		}
	}
	&__ttl {
		margin-bottom: 23px;
		@include mq {
			text-align: center;
			margin-bottom: 5px;
		}
	}
	&__des {
		@include font-size(30);
		font-weight: 500;
		line-height: 1.4;
		@include mq(mac) {
			@include font-size(24);
		}
		@include mq {
			@include font-size(18);
			line-height: 1.67;
		}
	}
	&-box {
		&__item {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 127px;
			@include mq(lp) {
				margin-bottom: 80px;
			}
			@include mq {
				display: block;
				background: rgba(#374EA2, 0.5);
				padding: 79px 40px 0;
				margin-bottom: 0;
			}
			@include mq(xs) {
				padding: 50px 15px 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-child(even) {
				flex-direction: row-reverse;
				.guide-box {
					&__wrap {
						@include mq(mac-min) {
							padding: 165px 107px 165px 210px;
						}
					}
				}
			}
			&.style02 {
				.guide-box {
					@include mq(md-min) {
						&__topic {
							text-align: left;
							padding-left: 3px;
						}
						&__wrap {
							//background: rgba(#CCB138, 0.5);
							background: rgba(192, 172, 43, 1);
							color: #231F20;
						}
					}
				}
				@include mq {
					background: rgba(192, 172, 43, 1);
					color: #231F20;
				}
			}
			&.style03 {
				.guide-box {
					@include mq(md-min) {
						&__wrap {
							//background: rgba(#DC4E34, 0.5);
							background: rgba(207, 71, 40, 1);
						}
					}
				}
				@include mq {
					background: rgba(207, 71, 40, 1);
				}
			}
		}
		&__topic {
			width: 210px;
			text-align: right;
			padding: 9px 12px 10px 10px;
			@include mq(mac) {
				width: 8vw;
			}
			@include mq {
				width: 100%;
				text-align: center;
				padding: 0;
				margin-bottom: 79px;
			}
			@include mq(xs) {
				margin-bottom: 40px;
			}
		}
		&__wrap {
			width: calc(100% - 210px);
			padding: 165px 120px 165px 197px;
			@include mq(mac) {
				width: calc(100% - 8vw);
				padding: 100px 5vw 100px 8vw;
			}
			@include mq(md-min) {
				//background: rgba(#374EA2, 0.5);
				background: rgba(38, 60, 134, 1);
				color: white;
			}
			@include mq {
				width: 100%;
				padding: 0;
				max-width: 450px;
				margin: 0 auto;
			}
		}
		&__btn {
			width: 100%;
			margin-top: 126px;
			@include mq(lp) {
				margin-top: 60px;
			}
			@include mq {
				background: white;
				width: auto;
				margin: 0 -40px;
				padding: 79px 0 80px;
			}
			@include mq(xs) {
				margin: 0 -15px;
				padding: 50px 0;
			}
		}
	}
	&-list {
		@include font-size(30);
		font-weight: 500;
		line-height: 1.35;
		margin: 0 auto;
		@include mq(mac) {
			@include font-size(22);
		}
		@include mq {
			@include font-size(18);
			line-height: 1.56;
			margin-bottom: 72px;
		}
		&__item {
			display: flex;
			margin-bottom: 199px;
			@include mq(mac) {
				margin-bottom: 100px;
			}
			@include mq {
				display: block;
				margin-bottom: 72px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__img {
			width: 39.56%;
			@include mq {
				width: 100%;
				margin-bottom: 47px;
			}
			img {
				display: block;
				width: 100%;
			}
		}
		&__content {
			width: 60.44%;
			max-width: 808px;
			margin-left: auto;
			padding: 0 0 0 15px;
			@include mq {
				width: 100%;
				margin:  0 auto;
				padding: 0;
			}
		}
		&__number {
			@include mq(lp) {
				img {
					height: 100px;
				}
			}
			@include mq {
				width: 72px;
				margin-top: 2px;
				img {
					height: 49px;
				}
			}
		}
		&__ttl {
			padding-left: 3px;
			margin: 50px 0;
			@include mq(mac) {
				margin: 30px 0;
			}
			@include mq(lp) {
				img {
					height: 2.8vw;
				}
			}
			@include mq {
				width: calc(100% - 72px);
				margin: 0;
				img {
					height: auto;
				}
			}
		}
		&__des {
			width: 100%;
			max-width: 698px;
		}
		&__top {
			@include mq {
				display: flex;
				align-items: center;
				margin-bottom: 45px;
			}
		}
	}
}


.sec-choose {
	padding: 101px 0 99px;
	margin-bottom: 300px;
	@include mq(lp) {
		margin-bottom: 200px;
	}
	@include mq {
		padding: 80px 0;
		margin-bottom: 80px;
	}
	@include mq(xs) {
		padding: 50px 0;
		margin-bottom: 50px;
	}
}
.choose {
	&__ttl {
		margin-bottom: 105px;
		@include mq {
			margin-bottom: 82px;
		}
		@include mq(xs) {
			margin-bottom: 50px;
		}
	}
	&-list {
		&__item {
			background: white;
			margin-bottom: 100px;
			padding: 84px 108px 91px;
			@include font-size(24);
			font-weight: 500;
			line-height: 1.42;
			@include mq(lp) {
				@include font-size(21);
			}
			@include mq {
				@include font-size(18);
				line-height: 1.56;
				padding: 50px 25px 42px;
				margin-bottom: 80px;
			}
			@include mq(xs) {
				@include font-size(15);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__ttl {
			margin-bottom: 37px;
			@include mq {
				text-align: center;
				margin-bottom: 20px;
			}
		}
		&__txt {
			padding: 0 2px;
			@include mq {
				padding: 0;
			}
		}
	}
}


.sec-info {
	padding: 101px 0 94px;
	margin-bottom: 300px;
	@include mq(lp) {
		margin-bottom: 200px;
	}
	@include mq {
		padding: 80px 0;
		margin-bottom: 80px;
	}
	@include mq(xs) {
		padding: 50px 0;
		margin-bottom: 50px;
	}
}
.info {
	&__ttl {
		margin-bottom: 104px;
		@include mq {
			margin-bottom: 82px;
		}
		@include mq(xs) {
			margin-bottom: 50px;
		}
	}
	&__txt {
		@include font-size(24);
		line-height: 1.8;
		margin-bottom: 90px;
		@include mq(lp) {
			@include font-size(20);
		}
		@include mq {
			@include font-size(15);
			line-height: 1.4;
			padding: 0 25px;
			margin-bottom: 76px;
		}
		@include mq(xs) {
			padding: 0 10px;
			margin-bottom: 50px;
		}
		span {
			color: #8a8bad;
			font-weight: 500;

		}
		.color{
			&--purple {
				color: #8a8bad;
			}
			&--blue {
				color: #7794b5;
			}
			&--green {
				color: #a1b587;
			}
			&--emerald {
				color: #6a9b72;
			}
			&--olive {
				color: #a7ab78;
			}
			&--yellow {
				color: #c2ae71;
			}
			&--pink {
				color: #ca909b;
			}
			&--red {
				color: #c87574;
			}
		}
		li {
			@include mq {
				margin-bottom: 18px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&__box {
		margin-bottom: 20px;
	}
}