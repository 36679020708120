.sec-map {
	text-align: center;
	padding: 0 15px 140px;
	@include mq {
		padding: 2px 15px 38px;
	}
	.map-ttl {
		padding: 0 20px 0;
	}
	.map-wrap {
		//min-height: 288px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.map-list {
		display: flex;
		flex-wrap: wrap;
		margin: 15px -10px 0px;
		> li {
			width: 50%;
			padding: 0 10px;
			.item {
				font-weight: 400;
				@include font-size(18);
				line-height: 1;
				padding: 14px 3px;

				text-align: center;
				display: block;
				border: 4px solid;
				margin-top: 15px;
				@include mq(xs) {
					@include font-size(16);
				}
				&--purple {
					color: #8a8bad;
					border-color: #8a8bad;
				}
				&--blue {
					color: #7794b5;
					border-color: #7794b5;
				}
				&--green {
					color: #a1b587;
					border-color: #a1b587;
				}
				&--emerald {
					color: #6a9b72;
					border-color: #6a9b72;
				}
				&--olive {
					color: #a7ab78;
					border-color: #a7ab78;
				}
				&--yellow {
					color: #c2ae71;
					border-color: #c2ae71;
				}
				&--pink {
					color: #ca909b;
					border-color: #ca909b;
				}
				&--red {
					color: #c87574;
					border-color: #c87574;
				}
			}
		}
	}
}

.sec-trip {
	background-color: #d1d3d4;
	padding: 113px 0 91px;
	margin-bottom: 300px;
	@include mq {
		margin-bottom: 79px;
		padding: 79px 0 66px;
	}
}

.select-time {
	display: flex;
	margin: 0 -23px 98px;
	@include mq {
		margin: 0 -8px 50px;
	}
	&__input {
		border: none;
		width: 100%;
		box-shadow: inset 4px 4px 5px -2px #b5b7b9, -3px -3px 5px 0px #b5b7b9;
		color: #939598;
		font-weight: 500;
		@include font-size(30);
		font-family: "M PLUS 1p", sans-serif!important;
		line-height: 80px;
		height: 80px;
		padding: 0 21px;
		background: url("../img/cycling/ico-calendar.png") no-repeat right 10px center, #ffffff;
		@include mq {
			@include font-size(15);
			background-size: 26px;
			padding: 0 6px;
			height: 35px;
			line-height: 35px;
			box-shadow: inset 2px 2px 3px -1px #b5b7b9, -2px -2px 3px 0px #b5b7b9;
			background-position: right 6px center;
		}
		@include mq(xs) {
			height: 34px;
			line-height: 34px;
			background-size: 14px;
			background-position: right 4px center;
			@include font-size(13);
			padding: 0 5px;
		}
		&:focus {
			outline: none;
		}
		&::placeholder {
			color: #939598;
			opacity: 1;
			font-family: "M PLUS 1p", sans-serif!important;
		}
		&:-ms-input-placeholder {
			color: #939598;
			font-family: "M PLUS 1p", sans-serif!important;
		}
		&::-ms-input-placeholder {
			color: #939598;
			font-family: "M PLUS 1p", sans-serif!important;
		}
	}
	&__btn {
		color: #ffffff;
		font-weight: 500;
		@include font-size(50);
		line-height: 1;
		background-color: #35A586;
		border-radius: 10px;
		padding: 14px 15px 15px;
		text-align: center;
		display: block;
		border: none;
		font-family: $font-mplus;
		width: 100%;
		cursor: pointer;
		@include smooth-transition;
		&:focus {
			outline: none;
		}
		&:hover {
			opacity: 0.7;
		}
		@include mq {
			@include font-size(18);
			padding: 9px 5px 8px;
			border-radius: 4px;
		}
	}
	.item {
		padding: 0 23px;
		width: calc((100% - 223px) / 2);
		@include mq {
			padding: 0 8px;
			width: calc((100% - 69px) / 2);
		}
		&:last-child {
			width: 223px;
			padding-left: 0;
			@include mq {
				width: 69px;
			}
		}
		&:first-child {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				right: -12px;
				top: 50%;
				transform: translateY(-50%);
				height: 4px;
				width: 25px;
				background-color: #58595b;
				@include mq {
					right: -5px;
					width: 10px;
				}
			}
		}
	}
}

.trip-container {
	margin-bottom: 98px;
	@include mq {
		margin-bottom: 71px;
	}
	@include mq(xs) {
		margin-bottom: 40px;
	}
}

.trip-dashed .box-trip__head:after {
	border-style: dashed;
	top: 15px;
	bottom: 15px;
	@include mq {
		top: 4px;
		bottom: 4px;
		border-width: 1px;
	}
}

.box-trip {
	background-color: #fff;
	padding: 15px 26px;
	@include mq(){
		padding: 20px;
	}
	&__head {
		display: flex;
		//border: 3px solid;
		border-bottom: 10px solid;
		position: relative;
		padding: 0px 0px 14px;
		font-weight: 500;
		@include font-size(30);
		line-height: 1.3;
		color: #231815!important;
		margin-bottom: 20px;
		@include mq {
			margin-bottom: 0px;
			@include font-size(20);
			border-bottom: 3px solid;
			padding: 0px 0px 3px;
		}

	}
	&__group {
		display: flex;
		flex-wrap: nowrap;
		@include mq(){
			display: block;
		}
		.item {
			padding: 0px 35px;
			border-right: 2px solid;
			&:last-child{
				border-right: 0;
			}
			&:nth-child(1){
				width: 34.3%;
				padding-left: 0;
				.item__content{
					font-weight: 500!important;
					@include mq(){
						@include font-size(18);
					}
				}
			}
			&:nth-child(2){
				width: 24.3%;
			}
			&:nth-child(3){
				width: calc(100% - 34.3% - 24.3%);
				padding-right: 0;
			}
			@include mq {
				margin-bottom: 0px;
				align-items: flex-start;
				width: 100%!important;
				padding: 15px 0px;
				border-right: 0;
				border-bottom: 2px solid;
			}
			&__inner{
				display: flex;
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: 0;
				}
				.item__img{
					width: 40px;
					text-align: center;
					@include mq {
						width: 30px;
						margin-top: 4px;
					}
					@include mq(xs) {
						width: 30px;
					}
				}
				.item__content{
					width: calc(100% - 40px);
					padding-left: 10px;
					@include mq(){
						width: calc(100% - 30px);
						padding-left: 5px;
					}
				}
			}
			&__content {
				color: #231f20;
				@include font-size(24);
				line-height: 1.3;
				font-weight: 400;
				padding-top: 5px;
				p{
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				@include mq {
					@include font-size(16);
					line-height: 1.56;
					p:not(:last-child) {
						margin-bottom: 5px;
					}
				}

				.lb {
					font-weight: 500;
				}
			}
		}
	}
	&__more {
		text-align: center;
		color: #ffffff;
		background: #35A586;
		font-weight: 500;
		@include font-size(30);
		line-height: 1.2;
		padding: 8px 10px;
		max-width: 300px;
		position: relative;
		margin: 20px 0 50px auto;
		border-radius: 10px;
		box-shadow: 2px 3px 6px rgba(#231815,0.5);
		width: 100%;
		a{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@include mq {
			@include font-size(18);
			line-height: 1;
			padding: 8px 5px;
			margin: 10px auto 30px;
			max-width: 170px;
			img{
				max-width: 13px;
				height: auto;
			}
		}

	}
	&--purple {
		.box-trip {
			&__head {
				border-color: #8a8bad;
				color: #8a8bad;
			}
			&__txt {
				color: #8a8bad;
			}
			&__group .item__content .lb {
				color: #8a8bad;
			}
		}
		.item:nth-child(1) .item__content{
			color: #8a8bad;
		}
		.item{
			border-color: #8a8bad;
		}
	}
	&--blue {
		.box-trip {
			&__head {
				border-color: #7794b5;
				color: #7794b5;
			}
			&__txt {
				color: #7794b5;
			}
			&__group .item__content .lb {
				color: #7794b5;
			}
		}
		.item:nth-child(1) .item__content{
			color: #7794b5;
		}
		.item{
			border-color: #7794b5;
		}
	}
	&--green {
		.box-trip {
			&__head {
				border-color: #a1b587;
				color: #a1b587;
			}
			&__txt {
				color: #a1b587;
			}
			&__group .item__content .lb {
				color: #a1b587;
			}
		}
		.item:nth-child(1) .item__content{
			color: #a1b587;
		}
		.item{
			border-color: #a1b587;
		}
	}
	&--emerald {
		.box-trip {
			&__head {
				border-color: #6a9b72;
				color: #6a9b72;
			}
			&__txt {
				color: #6a9b72;
			}
			&__group .item__content .lb {
				color: #6a9b72;
			}
		}
		.item:nth-child(1) .item__content{
			color: #6a9b72;
		}
		.item{
			border-color: #6a9b72;
		}
	}
	&--olive {
		.box-trip {
			&__head {
				border-color: #a7ab78;
				color: #a7ab78;
			}
			&__txt {
				color: #a7ab78;
			}
			&__group .item__content .lb {
				color: #a7ab78;
			}
		}
		.item:nth-child(1) .item__content{
			color: #a7ab78;
		}
		.item{
			border-color: #a7ab78;
		}
	}
	&--yellow {
		.box-trip {
			&__head {
				border-color: #c2ae71;
				color: #c2ae71;
			}
			&__txt {
				color: #c2ae71;
			}
			&__group .item__content .lb {
				color: #c2ae71;
			}
		}
		.item:nth-child(1) .item__content{
			color: #c2ae71;
		}
		.item{
			border-color: #c2ae71;
		}
	}
	&--pink {
		.box-trip {
			&__head {
				border-color: #ca909b;
				color: #ca909b;
			}
			&__txt {
				color: #ca909b;
			}
			&__group .item__content .lb {
				color: #ca909b;
			}
		}
		.item:nth-child(1) .item__content{
			color: #ca909b;
		}
		.item{
			border-color: #ca909b;
		}
	}
	&--red {
		.box-trip {
			&__head {
				border-color: #c87574;
				color: #c87574;
			}
			&__txt {
				color: #c87574;
			}
			&__group .item__content .lb {
				color: #c87574;
			}
		}
		.item:nth-child(1) .item__content{
			color: #c87574;
		}
		.item{
			border-color: #c87574;
		}
	}
}

.pagination{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 7px 0;
	> a, > span {
		display: block;
		margin: 0 10px;
		@include mq {
			margin: 0 5px;
		}
	}
	.current, .item {
		width: 40px;
		height: 40px;
		border: 1px solid #939598;
		display: flex;
		justify-content: center;
		align-items: center;
		@include font-size(28);
		color: #231f20;
		@include mq {
			width: 25px;
			height: 25px;
			@include font-size(21);
		}
	}
	.current {
		background-color: #939598;
		border: none!important;
	}
	.prev,.previouspostslink {
		margin: 0 8px 0 0;
		@include mq {
			margin: 0 14px 0 0;
		}
	}
	.next,.nextpostslink {
		margin: 0 0 0 8px;
		@include mq {
			margin: 0 0 0 14px;
		}
	}
}

.wp-pagenavi{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 7px 0;
	> a, > span {
		display: block;
		margin: 0 10px!important;
		@include mq {
			margin: 0 5px!important;
		}
	}
	.current,a,span {
		width: 40px;
		height: 40px;
		border: 1px solid #939598;
		display: flex;
		justify-content: center;
		align-items: center;
		@include font-size(28);
		color: #231f20;
		@include mq {
			width: 25px;
			height: 25px;
			@include font-size(21);
		}
	}
	.current {
		background-color: #939598;
		border: none!important;
	}
	.prev,.previouspostslink {
		margin: 0 8px 0 0;
		border: none!important;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			background: url("../img/cycling/ico-prev.png") no-repeat center/cover;
			width: 34px;
			height: 44px;
			top: 0;
			left: 0;
			@include mq(){
				width: 21px;
				height: 29px;
				background: url("../img/cycling/ico-prev-sp.png") no-repeat center/cover;
			}
		}
		@include mq {
			margin: 0 14px 0 0;
		}
	}
	.next,.nextpostslink {
		margin: 0 0 0 8px;
		border: none!important;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			background: url("../img/cycling/ico-next.png") no-repeat center/cover;
			width: 34px;
			height: 44px;
			top: 0;
			right: 0;
			@include mq(){
				width: 21px;
				height: 29px;
				background: url("../img/cycling/ico-next.png") no-repeat center/cover;
			}
		}
		@include mq {
			margin: 0 0 0 14px;
		}
	}
}