/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap");
html {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Position & transfo
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
/* ====================================================
Misc
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

.inner {
  display: block;
  width: 100%;
  max-width: 1530px;
  padding: 0 15px;
  margin: 0 auto;
}

.inner-1500 {
  max-width: 1530px;
}

.inner-1350 {
  max-width: 1380px;
}

.inner-1200 {
  max-width: 1230px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.ttl-comp03 {
  text-align: center;
  margin-bottom: 106px;
}

@media screen and (max-width: 834px) {
  .ttl-comp03 {
    margin-bottom: 52px;
  }
}

.txt-basic {
  line-height: 1.8;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .txt-basic {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.btn-comp01 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .btn-comp01 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp01 {
    font-size: 20px;
    font-size: 2rem;
  }
}

.btn-comp01:before {
  content: '';
  display: inline-block;
  width: 34px;
  height: 44px;
  background: url("../img/index/ico-arrow-right.png") no-repeat;
  background-size: cover;
  background-position: center right;
  vertical-align: middle;
  margin: -1px -4px 0 0;
}

@media screen and (max-width: 1440px) {
  .btn-comp01:before {
    width: 24px;
    height: 30px;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp01:before {
    width: 16px;
    height: 19px;
    position: absolute;
    top: 6px;
    left: 0;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp01 {
    padding-left: 18px;
  }
}

.btn-comp01:after {
  position: absolute;
  content: '';
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
  background: #231F20;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 0;
}

.btn-comp01:hover:after {
  width: 100%;
}

@media screen and (max-width: 834px) {
  .btn-comp01 span {
    display: inline-block;
    position: relative;
    padding-bottom: 5px;
    border-bottom: 1px solid #58595B;
  }
}

@media screen and (max-width: 834px) {
  .btn-comp01.mac:before {
    margin: -1px 0 0 -1px;
  }
}

.bg-gray {
  background: #D1D3D4;
}

.txt-notice {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .txt-notice {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

area {
  outline: none;
}

.txt-intro {
  text-align: center;
  color: #231F20;
  font-family: "M PLUS 1p", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .txt-intro {
    font-size: 8.5px;
    font-size: 0.85rem;
    margin-bottom: 20px;
    text-align: left;
  }
}

body {
  color: #231F20;
  font-family: "M PLUS 1p", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow: hidden;
}

.header {
  font-size: 30px;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
  color: #58595B;
  height: 160px;
  letter-spacing: 2px;
  white-space: nowrap;
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

@media screen and (max-width: 1770px) {
  .header {
    font-size: 25px;
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1200px) {
  .header {
    height: 120px;
    font-size: 20px;
    font-size: 2rem;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    height: 100px;
  }
}

@media screen and (max-width: 834px) {
  .header {
    height: 63px;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1772px;
  padding: 10px 15px 10px 16px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .header__inner {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 834px) {
  .header__logo {
    width: 175px;
  }
}

.header__logo img {
  display: block;
  width: 100%;
}

.header-nav {
  margin-left: auto;
}

@media screen and (max-width: 834px) {
  .header-nav {
    position: fixed;
    top: 20px;
    bottom: 0;
    left: 15px;
    right: 15px;
    background: white;
    opacity: 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    background: rgba(220, 221, 221, 0.9);
  }
  .header-nav.on {
    opacity: 1;
    pointer-events: auto;
  }
}

.header-menu {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

@media screen and (max-width: 1024px) {
  .header-menu {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu {
    display: block;
    margin-bottom: 80px;
    text-align: center;
    padding-top: 23px;
  }
}

.header-menu__item {
  margin-left: 47px;
  position: relative;
}

@media screen and (max-width: 1770px) {
  .header-menu__item {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .header-menu__item {
    margin-left: 20px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item {
    margin-left: 0;
    padding: 22px 15px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item.has-sub > a {
    text-decoration: none;
    color: #58595b9c;
  }
  .header-menu__item a {
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 500;
  }
}

.header-menu__item > a {
  display: block;
  position: relative;
  padding: 16px 0;
}

@media screen and (max-width: 834px) {
  .header-menu__item > a {
    padding: 0;
    display: inline-block;
    padding-bottom: 5px;
  }
}

.header-menu__item > a:before {
  position: absolute;
  content: '';
  bottom: 8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
  background: #231F20;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 0;
}

@media screen and (max-width: 834px) {
  .header-menu__item > a:before {
    display: none;
  }
}

.header-menu__item > a .ico {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  position: relative;
  background: #333;
  border-radius: 50%;
}

.header-menu__item > a .ico:before, .header-menu__item > a .ico:after {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
}

.header-menu__item > a .ico:before {
  width: 14px;
  height: 2px;
}

.header-menu__item > a .ico:after {
  height: 14px;
  width: 2px;
}

.header-menu__item > a.show .ico:after {
  display: none;
}

.header-menu__item.active > a:before {
  width: 100%;
}

@media screen and (max-width: 834px) {
  .header-menu__item.active > a:before {
    display: block;
  }
}

.header-menu__item:hover > a {
  opacity: 1;
}

.header-menu__item:hover > a:before {
  width: 100%;
}

.header-menu__item:hover .sub-menu {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.header-menu__item .sub-menu {
  background: #D1D3D4;
  padding: 23px 28px;
}

@media screen and (min-width: 835px) {
  .header-menu__item .sub-menu {
    position: absolute;
    top: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    pointer-events: none;
    min-width: 280px;
    transform: translateY(10px);
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item .sub-menu {
    background: none;
  }
}

.header-menu__item .sub-menu .item {
  margin-bottom: 23px;
}

.header-menu__item .sub-menu .item:last-child {
  margin-bottom: 0;
}

.header-control {
  display: none;
  margin-left: auto;
  width: 25px;
  height: 18px;
  position: relative;
  margin-bottom: 4px;
}

@media screen and (max-width: 834px) {
  .header-control {
    display: block;
  }
}

.header-control span {
  position: absolute;
  height: 2px;
  background: #58595B;
  width: 100%;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.header-control span:first-child {
  top: 0;
}

.header-control span:nth-of-type(2) {
  top: 46%;
}

.header-control span:last-child {
  bottom: 0;
}

.header-control.close span:first-child {
  top: 46%;
  transform: rotate(45deg);
}

.header-control.close span:nth-of-type(2) {
  opacity: 0;
}

.header-control.close span:last-child {
  bottom: 46%;
  transform: rotate(-45deg);
}

.main-page {
  margin-top: 160px;
}

@media screen and (max-width: 1200px) {
  .main-page {
    margin-top: 120px;
  }
}

@media screen and (max-width: 1024px) {
  .main-page {
    margin-top: 100px;
  }
}

@media screen and (max-width: 834px) {
  .main-page {
    margin-top: 63px;
  }
}

.footer {
  padding: 51px 15px 40px;
  border-top: 20px solid #939598;
  color: #58595B;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.75px;
}

@media screen and (max-width: 1200px) {
  .footer {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .footer {
    font-size: 12px;
    font-size: 1.2rem;
    border-width: 10px;
    line-height: 1.25;
    padding: 10px 15px 23px;
    letter-spacing: 0.55px;
  }
}

@media screen and (max-width: 374px) {
  .footer {
    letter-spacing: 0px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.footer-list {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 1;
}

@media screen and (max-width: 1200px) {
  .footer-list {
    font-size: 23px;
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 834px) {
  .footer-list {
    font-size: 15px;
    font-size: 1.5rem;
    letter-spacing: 0;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 640px) {
  .footer-list {
    flex-wrap: wrap;
  }
}

.footer-list__item {
  padding: 0 43px;
  border-left: 1px solid #939598;
}

@media screen and (max-width: 834px) {
  .footer-list__item {
    padding: 0 25px;
  }
}

@media screen and (max-width: 640px) {
  .footer-list__item {
    width: 50%;
    margin-bottom: 15px;
  }
  .footer-list__item:nth-child(3n) {
    border-left: none;
  }
}

@media screen and (max-width: 374px) {
  .footer-list__item {
    padding: 0 5px;
  }
}

.footer-list__item:first-child {
  border-left: none;
}

.page-about .sec-main-visual .inner {
  display: flex;
  justify-content: center;
}

.page-about .sec-main-visual .inner .main-visual__ttl {
  padding-left: 15px;
}

.sec-main-visual {
  position: relative;
}

.sec-main-visual .main-visual__img {
  position: relative;
}

.sec-main-visual .main-visual__img img {
  width: 100%;
}

.sec-main-visual .inner {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.sec-main-visual .inner .main-visual__ttl {
  position: absolute;
  top: 0;
  padding-right: 15px;
  text-align: center;
  width: auto;
}

@media screen and (max-width: 834px) {
  .sec-main-visual .inner .main-visual__ttl {
    position: static;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 834px) {
  .sec-main-visual .inner {
    position: static;
    transform: none;
  }
}

.sec-main-visual .inner-1800 {
  display: block;
  width: 100%;
  max-width: 1826px;
  padding: 0 15px;
  margin: 0 auto;
  position: absolute;
  bottom: 32px;
}

@media screen and (max-width: 834px) {
  .sec-main-visual .inner-1800 {
    position: static;
  }
}

.sec-main-visual .main-visual__list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  .sec-main-visual .main-visual__list {
    display: block;
    margin-top: 80px;
    text-align: center;
    margin-bottom: 30px;
  }
}

.sec-main-visual .main-visual__list .item a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  color: #231815;
  font-weight: 400;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1;
  position: relative;
  padding: 17px 18px;
  background: rgba(255, 255, 255, 0.8);
}

.sec-main-visual .main-visual__list .item a img {
  margin-right: 10px;
}

@media screen and (max-width: 834px) {
  .sec-main-visual .main-visual__list .item a img {
    width: 15px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 1400px) {
  .sec-main-visual .main-visual__list .item a {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 834px) {
  .sec-main-visual .main-visual__list .item a {
    padding: 0;
    margin: 0 0px 50px;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    color: #231f20;
  }
}

.sec-main-group {
  padding: 7.883% 0;
}

@media screen and (max-width: 834px) {
  .sec-main-group {
    padding: 5px 25px 80px;
  }
}

.sec-main-group .inner {
  display: flex;
  flex-wrap: wrap;
}

.main-group__block {
  width: 71%;
  padding-right: 7.4%;
}

@media screen and (max-width: 834px) {
  .main-group__block {
    width: 100%;
    padding-right: 0;
    margin-bottom: 80px;
    text-align: center;
  }
}

.main-group__embed {
  width: 29%;
}

@media screen and (max-width: 834px) {
  .main-group__embed {
    width: 100%;
    text-align: center;
  }
  .main-group__embed img {
    width: 295px;
  }
}

@media screen and (max-width: 834px) {
  .main-group__embed iframe {
    width: 78.1vw;
    max-width: 435px;
    display: block;
    margin: auto;
  }
}

@media screen and (max-width: 374px) {
  .main-group__embed iframe {
    width: 75.1vw;
  }
}

@media screen and (min-width: 835px) {
  .main-group__embed iframe {
    height: 42vw;
    max-height: 648px;
    width: 100%;
  }
}

.sec-contact {
  padding-top: 170px;
}

@media screen and (max-width: 834px) {
  .sec-contact {
    padding-top: 50px;
  }
}

@media screen and (max-width: 640px) {
  .sec-contact {
    margin-bottom: 39px;
    padding: 26px 25px 0;
  }
}

@media screen and (max-width: 374px) {
  .sec-contact {
    padding: 26px 15px 0;
  }
}

.sec-contact .ttl-contact {
  margin-bottom: 100px;
}

@media screen and (max-width: 834px) {
  .sec-contact .ttl-contact {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .sec-contact .ttl-contact {
    margin-bottom: 41px;
  }
}

.form-contact label {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1;
  color: #231f20;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  font-weight: 300;
}

@media screen and (max-width: 834px) {
  .form-contact label {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 640px) {
  .form-contact label {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 2px;
  }
}

.form-contact label .requite {
  font-size: 30px;
  font-size: 3rem;
  color: #ffffff;
  font-weight: 300;
  line-height: 1;
  padding: 5px 12px;
  background: #ccb138;
  margin-left: 17px;
}

@media screen and (max-width: 834px) {
  .form-contact label .requite {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 640px) {
  .form-contact label .requite {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1;
    padding: 3px 7px;
    margin-left: 13px;
  }
}

.form-contact input[type="text"], .form-contact input[type="email"] {
  width: 60%;
  padding: 25px 20px;
  border: 1px solid #231f20;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.5;
}

@media screen and (max-width: 834px) {
  .form-contact input[type="text"], .form-contact input[type="email"] {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 640px) {
  .form-contact input[type="text"], .form-contact input[type="email"] {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .form-contact input[type="text"], .form-contact input[type="email"] {
    padding: 18px 15px;
  }
}

@media screen and (max-width: 640px) {
  .form-contact input[type="text"], .form-contact input[type="email"] {
    width: 100%;
    padding: 3px 10px;
  }
}

.form-contact .inline-group {
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;
}

.form-contact .inline-group:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .form-contact .inline-group {
    margin-bottom: 38px;
  }
}

.form-contact textarea {
  width: 100%;
  resize: none;
  min-height: 700px;
  padding: 15px;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.5;
  border: 1px solid #231f20;
}

@media screen and (max-width: 834px) {
  .form-contact textarea {
    min-height: 350px;
  }
}

@media screen and (max-width: 640px) {
  .form-contact textarea {
    font-size: 18px;
    font-size: 1.8rem;
    min-height: 219px;
  }
}

.form-contact__main {
  margin-bottom: 94px;
}

@media screen and (max-width: 834px) {
  .form-contact__main {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .form-contact__main {
    margin-bottom: 17px;
  }
}

.btn-submit {
  margin: 0px 0 167px;
}

@media screen and (max-width: 834px) {
  .btn-submit {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .btn-submit {
    margin-bottom: 0;
  }
}

.btn-submit .btn {
  font-size: 50px;
  font-size: 5rem;
  line-height: 1;
  font-weight: 300;
  color: #fff;
  text-align: center;
  padding: 15px 61px;
  border: none;
  border-radius: 7px;
  outline: none;
  font-family: "M PLUS 1p", sans-serif;
  box-shadow: none;
  background-image: none;
  background: #ccb138;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-appearance: none;
}

.btn-submit .btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 834px) {
  .btn-submit .btn:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 834px) {
  .btn-submit .btn {
    font-size: 35px;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 640px) {
  .btn-submit .btn {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1;
    padding: 5px 14px;
  }
}

.mw_wp_form_confirm .form-contact .inline-group {
  font-size: 25px;
  font-size: 2.5rem;
}

@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .form-contact .inline-group {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.mw_wp_form_confirm .requite {
  display: none;
}

.mw_wp_form_confirm .btn-submit .btn {
  min-width: 350px;
  margin: 0px 10px;
}

@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .btn-submit .btn {
    min-width: 230px;
    margin: 7px auto;
  }
}

.mw_wp_form_confirm .btn-submit {
  text-align: center;
}

.thanks-content {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.6;
  margin-bottom: 100px;
}

@media screen and (max-width: 834px) {
  .thanks-content {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 30px;
  }
}

.wpcf7-not-valid-tip {
  font-size: 20px;
  font-size: 2rem;
  margin-top: 10px;
}

@media screen and (max-width: 834px) {
  .wpcf7-not-valid-tip {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.wpcf7 form .wpcf7-response-output {
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 834px) {
  .wpcf7 form .wpcf7-response-output {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

form.wpcf7-form.custom-wpcf7c-confirmed .requite {
  display: none;
}

form.wpcf7-form.custom-wpcf7c-confirmed input[type="text"], form.wpcf7-form.custom-wpcf7c-confirmed input[type="email"], form.wpcf7-form.custom-wpcf7c-confirmed textarea {
  border: none !important;
  background: none !important;
}

form.wpcf7-form.custom-wpcf7c-confirmed textarea {
  min-height: inherit;
}

form.wpcf7-form.custom-wpcf7c-confirmed .btn-submit {
  display: flex;
}

form.wpcf7-form.custom-wpcf7c-confirmed .btn-submit > * {
  margin: 0px 5px;
}

.sec-map {
  text-align: center;
  padding: 0 15px 140px;
}

@media screen and (max-width: 834px) {
  .sec-map {
    padding: 2px 15px 38px;
  }
}

.sec-map .map-ttl {
  padding: 0 20px 0;
}

.sec-map .map-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec-map .map-list {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -10px 0px;
}

.sec-map .map-list > li {
  width: 50%;
  padding: 0 10px;
}

.sec-map .map-list > li .item {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  padding: 14px 3px;
  text-align: center;
  display: block;
  border: 4px solid;
  margin-top: 15px;
}

@media screen and (max-width: 374px) {
  .sec-map .map-list > li .item {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.sec-map .map-list > li .item--purple {
  color: #8a8bad;
  border-color: #8a8bad;
}

.sec-map .map-list > li .item--blue {
  color: #7794b5;
  border-color: #7794b5;
}

.sec-map .map-list > li .item--green {
  color: #a1b587;
  border-color: #a1b587;
}

.sec-map .map-list > li .item--emerald {
  color: #6a9b72;
  border-color: #6a9b72;
}

.sec-map .map-list > li .item--olive {
  color: #a7ab78;
  border-color: #a7ab78;
}

.sec-map .map-list > li .item--yellow {
  color: #c2ae71;
  border-color: #c2ae71;
}

.sec-map .map-list > li .item--pink {
  color: #ca909b;
  border-color: #ca909b;
}

.sec-map .map-list > li .item--red {
  color: #c87574;
  border-color: #c87574;
}

.sec-trip {
  background-color: #d1d3d4;
  padding: 113px 0 91px;
  margin-bottom: 300px;
}

@media screen and (max-width: 834px) {
  .sec-trip {
    margin-bottom: 79px;
    padding: 79px 0 66px;
  }
}

.select-time {
  display: flex;
  margin: 0 -23px 98px;
}

@media screen and (max-width: 834px) {
  .select-time {
    margin: 0 -8px 50px;
  }
}

.select-time__input {
  border: none;
  width: 100%;
  box-shadow: inset 4px 4px 5px -2px #b5b7b9, -3px -3px 5px 0px #b5b7b9;
  color: #939598;
  font-weight: 500;
  font-size: 30px;
  font-size: 3rem;
  font-family: "M PLUS 1p", sans-serif !important;
  line-height: 80px;
  height: 80px;
  padding: 0 21px;
  background: url("../img/cycling/ico-calendar.png") no-repeat right 10px center, #ffffff;
}

@media screen and (max-width: 834px) {
  .select-time__input {
    font-size: 15px;
    font-size: 1.5rem;
    background-size: 26px;
    padding: 0 6px;
    height: 35px;
    line-height: 35px;
    box-shadow: inset 2px 2px 3px -1px #b5b7b9, -2px -2px 3px 0px #b5b7b9;
    background-position: right 6px center;
  }
}

@media screen and (max-width: 374px) {
  .select-time__input {
    height: 34px;
    line-height: 34px;
    background-size: 14px;
    background-position: right 4px center;
    font-size: 13px;
    font-size: 1.3rem;
    padding: 0 5px;
  }
}

.select-time__input:focus {
  outline: none;
}

.select-time__input::placeholder {
  color: #939598;
  opacity: 1;
  font-family: "M PLUS 1p", sans-serif !important;
}

.select-time__input:-ms-input-placeholder {
  color: #939598;
  font-family: "M PLUS 1p", sans-serif !important;
}

.select-time__input::-ms-input-placeholder {
  color: #939598;
  font-family: "M PLUS 1p", sans-serif !important;
}

.select-time__btn {
  color: #ffffff;
  font-weight: 500;
  font-size: 50px;
  font-size: 5rem;
  line-height: 1;
  background-color: #35A586;
  border-radius: 10px;
  padding: 14px 15px 15px;
  text-align: center;
  display: block;
  border: none;
  font-family: "M PLUS 1p", sans-serif;
  width: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.select-time__btn:focus {
  outline: none;
}

.select-time__btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 834px) {
  .select-time__btn {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 9px 5px 8px;
    border-radius: 4px;
  }
}

.select-time .item {
  padding: 0 23px;
  width: calc((100% - 223px) / 2);
}

@media screen and (max-width: 834px) {
  .select-time .item {
    padding: 0 8px;
    width: calc((100% - 69px) / 2);
  }
}

.select-time .item:last-child {
  width: 223px;
  padding-left: 0;
}

@media screen and (max-width: 834px) {
  .select-time .item:last-child {
    width: 69px;
  }
}

.select-time .item:first-child {
  position: relative;
}

.select-time .item:first-child:after {
  content: '';
  position: absolute;
  z-index: 1;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 25px;
  background-color: #58595b;
}

@media screen and (max-width: 834px) {
  .select-time .item:first-child:after {
    right: -5px;
    width: 10px;
  }
}

.trip-container {
  margin-bottom: 98px;
}

@media screen and (max-width: 834px) {
  .trip-container {
    margin-bottom: 71px;
  }
}

@media screen and (max-width: 374px) {
  .trip-container {
    margin-bottom: 40px;
  }
}

.trip-dashed .box-trip__head:after {
  border-style: dashed;
  top: 15px;
  bottom: 15px;
}

@media screen and (max-width: 834px) {
  .trip-dashed .box-trip__head:after {
    top: 4px;
    bottom: 4px;
    border-width: 1px;
  }
}

.box-trip {
  background-color: #fff;
  padding: 15px 26px;
}

@media screen and (max-width: 834px) {
  .box-trip {
    padding: 20px;
  }
}

.box-trip__head {
  display: flex;
  border-bottom: 10px solid;
  position: relative;
  padding: 0px 0px 14px;
  font-weight: 500;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.3;
  color: #231815 !important;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .box-trip__head {
    margin-bottom: 0px;
    font-size: 20px;
    font-size: 2rem;
    border-bottom: 3px solid;
    padding: 0px 0px 3px;
  }
}

.box-trip__group {
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 834px) {
  .box-trip__group {
    display: block;
  }
}

.box-trip__group .item {
  padding: 0px 35px;
  border-right: 2px solid;
}

.box-trip__group .item:last-child {
  border-right: 0;
}

.box-trip__group .item:nth-child(1) {
  width: 34.3%;
  padding-left: 0;
}

.box-trip__group .item:nth-child(1) .item__content {
  font-weight: 500 !important;
}

@media screen and (max-width: 834px) {
  .box-trip__group .item:nth-child(1) .item__content {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-trip__group .item:nth-child(2) {
  width: 24.3%;
}

.box-trip__group .item:nth-child(3) {
  width: calc(100% - 34.3% - 24.3%);
  padding-right: 0;
}

@media screen and (max-width: 834px) {
  .box-trip__group .item {
    margin-bottom: 0px;
    align-items: flex-start;
    width: 100% !important;
    padding: 15px 0px;
    border-right: 0;
    border-bottom: 2px solid;
  }
}

.box-trip__group .item__inner {
  display: flex;
  margin-bottom: 10px;
}

.box-trip__group .item__inner:last-child {
  margin-bottom: 0;
}

.box-trip__group .item__inner .item__img {
  width: 40px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .box-trip__group .item__inner .item__img {
    width: 30px;
    margin-top: 4px;
  }
}

@media screen and (max-width: 374px) {
  .box-trip__group .item__inner .item__img {
    width: 30px;
  }
}

.box-trip__group .item__inner .item__content {
  width: calc(100% - 40px);
  padding-left: 10px;
}

@media screen and (max-width: 834px) {
  .box-trip__group .item__inner .item__content {
    width: calc(100% - 30px);
    padding-left: 5px;
  }
}

.box-trip__group .item__content {
  color: #231f20;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 400;
  padding-top: 5px;
}

.box-trip__group .item__content p {
  margin-bottom: 10px;
}

.box-trip__group .item__content p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 834px) {
  .box-trip__group .item__content {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.56;
  }
  .box-trip__group .item__content p:not(:last-child) {
    margin-bottom: 5px;
  }
}

.box-trip__group .item__content .lb {
  font-weight: 500;
}

.box-trip__more {
  text-align: center;
  color: #ffffff;
  background: #35A586;
  font-weight: 500;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.2;
  padding: 8px 10px;
  max-width: 300px;
  position: relative;
  margin: 20px 0 50px auto;
  border-radius: 10px;
  box-shadow: 2px 3px 6px rgba(35, 24, 21, 0.5);
  width: 100%;
}

.box-trip__more a {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 834px) {
  .box-trip__more {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1;
    padding: 8px 5px;
    margin: 10px auto 30px;
    max-width: 170px;
  }
  .box-trip__more img {
    max-width: 13px;
    height: auto;
  }
}

.box-trip--purple .box-trip__head {
  border-color: #8a8bad;
  color: #8a8bad;
}

.box-trip--purple .box-trip__txt {
  color: #8a8bad;
}

.box-trip--purple .box-trip__group .item__content .lb {
  color: #8a8bad;
}

.box-trip--purple .item:nth-child(1) .item__content {
  color: #8a8bad;
}

.box-trip--purple .item {
  border-color: #8a8bad;
}

.box-trip--blue .box-trip__head {
  border-color: #7794b5;
  color: #7794b5;
}

.box-trip--blue .box-trip__txt {
  color: #7794b5;
}

.box-trip--blue .box-trip__group .item__content .lb {
  color: #7794b5;
}

.box-trip--blue .item:nth-child(1) .item__content {
  color: #7794b5;
}

.box-trip--blue .item {
  border-color: #7794b5;
}

.box-trip--green .box-trip__head {
  border-color: #a1b587;
  color: #a1b587;
}

.box-trip--green .box-trip__txt {
  color: #a1b587;
}

.box-trip--green .box-trip__group .item__content .lb {
  color: #a1b587;
}

.box-trip--green .item:nth-child(1) .item__content {
  color: #a1b587;
}

.box-trip--green .item {
  border-color: #a1b587;
}

.box-trip--emerald .box-trip__head {
  border-color: #6a9b72;
  color: #6a9b72;
}

.box-trip--emerald .box-trip__txt {
  color: #6a9b72;
}

.box-trip--emerald .box-trip__group .item__content .lb {
  color: #6a9b72;
}

.box-trip--emerald .item:nth-child(1) .item__content {
  color: #6a9b72;
}

.box-trip--emerald .item {
  border-color: #6a9b72;
}

.box-trip--olive .box-trip__head {
  border-color: #a7ab78;
  color: #a7ab78;
}

.box-trip--olive .box-trip__txt {
  color: #a7ab78;
}

.box-trip--olive .box-trip__group .item__content .lb {
  color: #a7ab78;
}

.box-trip--olive .item:nth-child(1) .item__content {
  color: #a7ab78;
}

.box-trip--olive .item {
  border-color: #a7ab78;
}

.box-trip--yellow .box-trip__head {
  border-color: #c2ae71;
  color: #c2ae71;
}

.box-trip--yellow .box-trip__txt {
  color: #c2ae71;
}

.box-trip--yellow .box-trip__group .item__content .lb {
  color: #c2ae71;
}

.box-trip--yellow .item:nth-child(1) .item__content {
  color: #c2ae71;
}

.box-trip--yellow .item {
  border-color: #c2ae71;
}

.box-trip--pink .box-trip__head {
  border-color: #ca909b;
  color: #ca909b;
}

.box-trip--pink .box-trip__txt {
  color: #ca909b;
}

.box-trip--pink .box-trip__group .item__content .lb {
  color: #ca909b;
}

.box-trip--pink .item:nth-child(1) .item__content {
  color: #ca909b;
}

.box-trip--pink .item {
  border-color: #ca909b;
}

.box-trip--red .box-trip__head {
  border-color: #c87574;
  color: #c87574;
}

.box-trip--red .box-trip__txt {
  color: #c87574;
}

.box-trip--red .box-trip__group .item__content .lb {
  color: #c87574;
}

.box-trip--red .item:nth-child(1) .item__content {
  color: #c87574;
}

.box-trip--red .item {
  border-color: #c87574;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 7px 0;
}

.pagination > a, .pagination > span {
  display: block;
  margin: 0 10px;
}

@media screen and (max-width: 834px) {
  .pagination > a, .pagination > span {
    margin: 0 5px;
  }
}

.pagination .current, .pagination .item {
  width: 40px;
  height: 40px;
  border: 1px solid #939598;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-size: 2.8rem;
  color: #231f20;
}

@media screen and (max-width: 834px) {
  .pagination .current, .pagination .item {
    width: 25px;
    height: 25px;
    font-size: 21px;
    font-size: 2.1rem;
  }
}

.pagination .current {
  background-color: #939598;
  border: none !important;
}

.pagination .prev, .pagination .previouspostslink {
  margin: 0 8px 0 0;
}

@media screen and (max-width: 834px) {
  .pagination .prev, .pagination .previouspostslink {
    margin: 0 14px 0 0;
  }
}

.pagination .next, .pagination .nextpostslink {
  margin: 0 0 0 8px;
}

@media screen and (max-width: 834px) {
  .pagination .next, .pagination .nextpostslink {
    margin: 0 0 0 14px;
  }
}

.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 7px 0;
}

.wp-pagenavi > a, .wp-pagenavi > span {
  display: block;
  margin: 0 10px !important;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi > a, .wp-pagenavi > span {
    margin: 0 5px !important;
  }
}

.wp-pagenavi .current, .wp-pagenavi a, .wp-pagenavi span {
  width: 40px;
  height: 40px;
  border: 1px solid #939598;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-size: 2.8rem;
  color: #231f20;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi .current, .wp-pagenavi a, .wp-pagenavi span {
    width: 25px;
    height: 25px;
    font-size: 21px;
    font-size: 2.1rem;
  }
}

.wp-pagenavi .current {
  background-color: #939598;
  border: none !important;
}

.wp-pagenavi .prev, .wp-pagenavi .previouspostslink {
  margin: 0 8px 0 0;
  border: none !important;
  position: relative;
}

.wp-pagenavi .prev:after, .wp-pagenavi .previouspostslink:after {
  content: '';
  position: absolute;
  background: url("../img/cycling/ico-prev.png") no-repeat center/cover;
  width: 34px;
  height: 44px;
  top: 0;
  left: 0;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi .prev:after, .wp-pagenavi .previouspostslink:after {
    width: 21px;
    height: 29px;
    background: url("../img/cycling/ico-prev-sp.png") no-repeat center/cover;
  }
}

@media screen and (max-width: 834px) {
  .wp-pagenavi .prev, .wp-pagenavi .previouspostslink {
    margin: 0 14px 0 0;
  }
}

.wp-pagenavi .next, .wp-pagenavi .nextpostslink {
  margin: 0 0 0 8px;
  border: none !important;
  position: relative;
}

.wp-pagenavi .next:after, .wp-pagenavi .nextpostslink:after {
  content: '';
  position: absolute;
  background: url("../img/cycling/ico-next.png") no-repeat center/cover;
  width: 34px;
  height: 44px;
  top: 0;
  right: 0;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi .next:after, .wp-pagenavi .nextpostslink:after {
    width: 21px;
    height: 29px;
    background: url("../img/cycling/ico-next.png") no-repeat center/cover;
  }
}

@media screen and (max-width: 834px) {
  .wp-pagenavi .next, .wp-pagenavi .nextpostslink {
    margin: 0 0 0 14px;
  }
}

.sec-mv {
  position: relative;
  margin-bottom: 52px;
}

@media screen and (max-width: 834px) {
  .sec-mv {
    padding: 9px 0 0;
    margin-bottom: 81px;
  }
}

@media screen and (max-width: 834px) {
  .mv-img {
    margin-bottom: 79px;
  }
}

.mv-img img {
  display: block;
  width: 100%;
  min-height: 700px;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .mv-img img {
    min-height: auto;
  }
}

.mv-content {
  width: 100%;
  max-width: 1770px;
  padding: 0 15px;
  text-align: right;
}

@media screen and (min-width: 835px) {
  .mv-content {
    position: absolute;
    top: 3.4%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 834px) {
  .mv-content {
    text-align: center;
  }
}

.mv-logo {
  padding: 0 0px 9px 0;
  margin-right: -1px;
}

@media screen and (max-width: 834px) {
  .mv-logo {
    padding: 0 0px 6px 0;
    margin-right: 0;
  }
}

.mv-ttl {
  padding: 0 2px 58px 0;
}

@media screen and (max-width: 834px) {
  .mv-ttl {
    padding: 0 0 20px 0;
  }
}

.sec-guide {
  margin-bottom: 130px;
  margin-top: 50px;
}

@media screen and (max-width: 1440px) {
  .sec-guide {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 834px) {
  .sec-guide {
    margin-bottom: 0px;
    margin-top: 0;
  }
  .sec-guide .inner {
    padding: 0 !important;
  }
}

.guide__head {
  padding: 0 210px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1770px) {
  .guide__head {
    padding: 0 8vw;
  }
}

@media screen and (max-width: 834px) {
  .guide__head {
    padding: 0 10.6vw;
    margin-bottom: 72px;
  }
}

.guide__ttl {
  margin-bottom: 23px;
}

@media screen and (max-width: 834px) {
  .guide__ttl {
    text-align: center;
    margin-bottom: 5px;
  }
}

.guide__des {
  font-size: 30px;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 1770px) {
  .guide__des {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 834px) {
  .guide__des {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.67;
  }
}

.guide-box__item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 127px;
}

@media screen and (max-width: 1440px) {
  .guide-box__item {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__item {
    display: block;
    background: rgba(55, 78, 162, 0.5);
    padding: 79px 40px 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 374px) {
  .guide-box__item {
    padding: 50px 15px 0;
  }
}

.guide-box__item:last-child {
  margin-bottom: 0;
}

.guide-box__item:nth-child(even) {
  flex-direction: row-reverse;
}

@media screen and (min-width: 1771px) {
  .guide-box__item:nth-child(even) .guide-box__wrap {
    padding: 165px 107px 165px 210px;
  }
}

@media screen and (min-width: 835px) {
  .guide-box__item.style02 .guide-box__topic {
    text-align: left;
    padding-left: 3px;
  }
  .guide-box__item.style02 .guide-box__wrap {
    background: #c0ac2b;
    color: #231F20;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__item.style02 {
    background: #c0ac2b;
    color: #231F20;
  }
}

@media screen and (min-width: 835px) {
  .guide-box__item.style03 .guide-box__wrap {
    background: #cf4728;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__item.style03 {
    background: #cf4728;
  }
}

.guide-box__topic {
  width: 210px;
  text-align: right;
  padding: 9px 12px 10px 10px;
}

@media screen and (max-width: 1770px) {
  .guide-box__topic {
    width: 8vw;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__topic {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 79px;
  }
}

@media screen and (max-width: 374px) {
  .guide-box__topic {
    margin-bottom: 40px;
  }
}

.guide-box__wrap {
  width: calc(100% - 210px);
  padding: 165px 120px 165px 197px;
}

@media screen and (max-width: 1770px) {
  .guide-box__wrap {
    width: calc(100% - 8vw);
    padding: 100px 5vw 100px 8vw;
  }
}

@media screen and (min-width: 835px) {
  .guide-box__wrap {
    background: #263c86;
    color: white;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__wrap {
    width: 100%;
    padding: 0;
    max-width: 450px;
    margin: 0 auto;
  }
}

.guide-box__btn {
  width: 100%;
  margin-top: 126px;
}

@media screen and (max-width: 1440px) {
  .guide-box__btn {
    margin-top: 60px;
  }
}

@media screen and (max-width: 834px) {
  .guide-box__btn {
    background: white;
    width: auto;
    margin: 0 -40px;
    padding: 79px 0 80px;
  }
}

@media screen and (max-width: 374px) {
  .guide-box__btn {
    margin: 0 -15px;
    padding: 50px 0;
  }
}

.guide-list {
  font-size: 30px;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.35;
  margin: 0 auto;
}

@media screen and (max-width: 1770px) {
  .guide-list {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 834px) {
  .guide-list {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.56;
    margin-bottom: 72px;
  }
}

.guide-list__item {
  display: flex;
  margin-bottom: 199px;
}

@media screen and (max-width: 1770px) {
  .guide-list__item {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 834px) {
  .guide-list__item {
    display: block;
    margin-bottom: 72px;
  }
}

.guide-list__item:last-child {
  margin-bottom: 0;
}

.guide-list__img {
  width: 39.56%;
}

@media screen and (max-width: 834px) {
  .guide-list__img {
    width: 100%;
    margin-bottom: 47px;
  }
}

.guide-list__img img {
  display: block;
  width: 100%;
}

.guide-list__content {
  width: 60.44%;
  max-width: 808px;
  margin-left: auto;
  padding: 0 0 0 15px;
}

@media screen and (max-width: 834px) {
  .guide-list__content {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 1440px) {
  .guide-list__number img {
    height: 100px;
  }
}

@media screen and (max-width: 834px) {
  .guide-list__number {
    width: 72px;
    margin-top: 2px;
  }
  .guide-list__number img {
    height: 49px;
  }
}

.guide-list__ttl {
  padding-left: 3px;
  margin: 50px 0;
}

@media screen and (max-width: 1770px) {
  .guide-list__ttl {
    margin: 30px 0;
  }
}

@media screen and (max-width: 1440px) {
  .guide-list__ttl img {
    height: 2.8vw;
  }
}

@media screen and (max-width: 834px) {
  .guide-list__ttl {
    width: calc(100% - 72px);
    margin: 0;
  }
  .guide-list__ttl img {
    height: auto;
  }
}

.guide-list__des {
  width: 100%;
  max-width: 698px;
}

@media screen and (max-width: 834px) {
  .guide-list__top {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
  }
}

.sec-choose {
  padding: 101px 0 99px;
  margin-bottom: 300px;
}

@media screen and (max-width: 1440px) {
  .sec-choose {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 834px) {
  .sec-choose {
    padding: 80px 0;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 374px) {
  .sec-choose {
    padding: 50px 0;
    margin-bottom: 50px;
  }
}

.choose__ttl {
  margin-bottom: 105px;
}

@media screen and (max-width: 834px) {
  .choose__ttl {
    margin-bottom: 82px;
  }
}

@media screen and (max-width: 374px) {
  .choose__ttl {
    margin-bottom: 50px;
  }
}

.choose-list__item {
  background: white;
  margin-bottom: 100px;
  padding: 84px 108px 91px;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.42;
}

@media screen and (max-width: 1440px) {
  .choose-list__item {
    font-size: 21px;
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 834px) {
  .choose-list__item {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.56;
    padding: 50px 25px 42px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 374px) {
  .choose-list__item {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.choose-list__item:last-child {
  margin-bottom: 0;
}

.choose-list__ttl {
  margin-bottom: 37px;
}

@media screen and (max-width: 834px) {
  .choose-list__ttl {
    text-align: center;
    margin-bottom: 20px;
  }
}

.choose-list__txt {
  padding: 0 2px;
}

@media screen and (max-width: 834px) {
  .choose-list__txt {
    padding: 0;
  }
}

.sec-info {
  padding: 101px 0 94px;
  margin-bottom: 300px;
}

@media screen and (max-width: 1440px) {
  .sec-info {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 834px) {
  .sec-info {
    padding: 80px 0;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 374px) {
  .sec-info {
    padding: 50px 0;
    margin-bottom: 50px;
  }
}

.info__ttl {
  margin-bottom: 104px;
}

@media screen and (max-width: 834px) {
  .info__ttl {
    margin-bottom: 82px;
  }
}

@media screen and (max-width: 374px) {
  .info__ttl {
    margin-bottom: 50px;
  }
}

.info__txt {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.8;
  margin-bottom: 90px;
}

@media screen and (max-width: 1440px) {
  .info__txt {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .info__txt {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: 0 25px;
    margin-bottom: 76px;
  }
}

@media screen and (max-width: 374px) {
  .info__txt {
    padding: 0 10px;
    margin-bottom: 50px;
  }
}

.info__txt span {
  color: #8a8bad;
  font-weight: 500;
}

.info__txt .color--purple {
  color: #8a8bad;
}

.info__txt .color--blue {
  color: #7794b5;
}

.info__txt .color--green {
  color: #a1b587;
}

.info__txt .color--emerald {
  color: #6a9b72;
}

.info__txt .color--olive {
  color: #a7ab78;
}

.info__txt .color--yellow {
  color: #c2ae71;
}

.info__txt .color--pink {
  color: #ca909b;
}

.info__txt .color--red {
  color: #c87574;
}

@media screen and (max-width: 834px) {
  .info__txt li {
    margin-bottom: 18px;
  }
  .info__txt li:last-child {
    margin-bottom: 0;
  }
}

.info__box {
  margin-bottom: 20px;
}
